// TODO: check why using this in App.js or AppRouter.jsx it is undefined...
// keep in sync with BASE_URL in App.js
export const CONTEXT_PATH = '/account';
export const STATIC_ASSETS_BASE = 'https://static.ecosio.com/accounts';

export const CompanyTypes = {
  STANDARD: 'STANDARD',
  DEPARTMENT: 'DEPARTMENT',
  EXTERNAL: 'EXTERNAL',
  TEST: 'TEST',
  OWN: 'OWN',
  PARTNER: 'PARTNER',
};
