import React, {useEffect} from 'react';
import qs from 'qs';
import {intlShape} from '@ecosio/components';
import {injectIntl} from 'react-intl';
import {historyShape, locationShape} from '../../../../shapes';
import SettingsHeader from '../SettingsHeader';
import {SettingsPageContainer} from '../FileNamePatternPage/FilePatternStyles';
import {showWarningIfPresent} from '../../CustomLandingPage';
import SettingsPages from '../settingsPages';
import AccountForm from './AccountForm';

const AccountPage = (props) => {
  const {history, location, intl} = props;

  useEffect(() => {
    const query = qs.parse(location.search, {ignoreQueryPrefix: true});
    showWarningIfPresent(query, intl);
  }, [location.search, intl]);

  return (
    <div>
      <SettingsPageContainer>
        <SettingsHeader page={SettingsPages.ACCOUNT} />
        <AccountForm history={history} />
      </SettingsPageContainer>
    </div>
  );
};

AccountPage.propTypes = {
  history: historyShape.isRequired,
  location: locationShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(AccountPage);
